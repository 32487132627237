import React from 'react';
import Img from 'gatsby-image'

const ZoneCard = ({ input }) => {
  // console.log(input)
  const {
    title,
    tabs,
    imageFeatured
  } = input
// console.log(tabs)
  return (
    <div className="zone-card">
      {imageFeatured && 
        <div className="image hidden-xs">
          <Img {...imageFeatured.asset} />
          <div className="site-name fM fSeg ">
            TARA INTERNATIONAL<br/>
            POLAR STATION
          </div>
        </div>
      }
      <div className="header">
        <h2 className="fM ttu">{title}</h2>
        <ul className="strong">
        {tabs.tab.map((item,i) => (
          <li key={i} className="ttu fPrim strong">{item.title}</li>
        ))}
        </ul>
      </div>
    </div>
  );
};

export default ZoneCard;