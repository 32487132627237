import React, { useState } from 'react';
import Img from 'gatsby-image'
import BgImg from 'gatsby-background-image'
import { useEffect } from 'react';

const ImageZoomable = ({image}) => {
  const [show, setShow] = useState()

  useEffect(() => {
    document.addEventListener("keydown", _onKeyDown)

    return () => document.removeEventListener("keydown", _onKeyDown)
  }, [])

  const _onKeyDown = (e) => {
    if(e.keyCode == "27"){
      _close()
    }
  }

  const _open = () => {
    setShow(true)
  }

  const _close = () => {
    setShow(false)
  }
// console.log(image)
  return (
    <div className="image-zoomable ">
      <div className="curp" onClick={_open}>
        <Img fluid={image.asset.fluid} Tag="figure" />
      </div>
      
      <div className="ico-zoom curp" onClick={_open}>
        <i className="icon icon-fullscreen-on"></i>
      </div>
      {show && <ImageModal image={image} close={_close}/>}
    </div>
  );
};

export default ImageZoomable;

const ImageModal = ({image, close}) => (
  <div className="image-modal">
    <div className="bg-close curp w100 h100" onClick={close}></div>
    <div className="container">
      <div className="row">
        <div className="col-md-1 hidden-xs"></div>
        <div className="col-md-10 col-xs-12">
          <div className="inner">
            <div className="cover" style={{
              height: '90vh',
              width: '100%',
              backgroundImage: `url(${image.asset.url})`,
              backgroundSize: "cover"
            }}>

            </div>
            {/* <BgImg 
            {...image.asset} 
            Tag="figure" 
            imgStyle={{
              height: '90vh',
              width: '90vw'
            }}
            /> */}
            <div className="ico-close curp" onClick={close}>
              <i className="icon icon-close"></i>
            </div>
          </div>
        </div>
        <div className="col-md-1 hidden-xs"></div>
      </div>
    </div>
    
  </div>
)