import React, { useState, useRef } from 'react';
import PubSub from 'pubsub-js'
import { useEffect } from 'react';

const Tabs = ({input}) => (
  <div className="tabs">
    {input.tab.map((item,i) => (
      <TabItem key={i} index={i+1} input={item} />
    ))}
  </div>
)

export default Tabs;

const TabItem = ({ index, input }) => {
  const [active, setActive] = useState(false)
  const texteRef = useRef();

  useEffect(() => {
    const token = PubSub.subscribe("TAB_ACTIVE", (e,d) => {
      console.log(d)
      if(d !== index){
        setActive(false)
      }
    })

    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if(active){
      // _open()
      PubSub.publish("TAB_ACTIVE", index)
    }
  }, [active])

  const _onClick = (e) => {
    setActive(!active)
  }

  const _open = () => {
    // console.log(texteRef.current)
    // console.log(texteRef.current.offsetHeight)
    // // const {height} = texteRef.current.getBoundingClientRect()
    // const height = texteRef.current.offsetHeight
    // texteRef.current.style.height = "0px"
    // setTimeout(() => {
    //   texteRef.current.style.height = height+"px"
    // }, 50)  
  }

  const _close = () => {
    // texteRef.current.style.height = "0px"
  }

  return(
    <div className={`tab-item ${(active ? 'is-active' : '')}`} >
      <div className="tab-item--header curp x strong fPrim" onClick={_onClick}>
        <>
          <div className="index fSeg strong">{index}</div>
          <div className="title strong ttu">{input.title}</div>
          {active
          ? <span className="icon icon-chevron-n"></span>
          : <span className="icon icon-chevron-s"></span>
          }
        </>
      </div>
      <div className={`tab-item--texte ${(active ? '' : 'collapsed')}`} ref={texteRef}>
        <div className="inner">
          {input.texte}
        </div>
      </div>
    </div>
  )
}