import React, { useState, useEffect, useContext } from 'react';
import { WrapperContext } from './Layout';
import { uuid } from '../lib/helpers'
// import { callApi } from '../lib/data'
import { _getLocalizedText } from '../lib/helpers'
// const { api: { projectId, dataset } } = require('../../../back/sanity.json')

const Form = ({zoneId, zoneName, onSuccess}) => {
  const _WrapperContext = useContext(WrapperContext)
  const { settings:{codesBanque} } = _WrapperContext
  // console.log(codesBanque)

  const [status, setStatus] = useState("initial")
  const [message, setMessage] = useState("")
  const [data, setData] = useState()
  // const endpoint = `https://${projectId}.api.sanity.io/v1/data/mutate/production`
  
  const fields = [
    {
      name: 'last_name',
      type: 'text'
    }, 
    {
      name: 'first_name',
      type: 'text'
    }, 
    {
      name: 'email',
      type: 'email'
    }, 
    {
      name: 'contribution',
      type: 'number',
      min: 1
    }, 
    {
      name: 'code_banquier',
      type: 'text'
    }, 
    {
      name: 'optin',
      type: 'checkbox'
    }
  ]

  useEffect(() => {
    console.log(zoneId, zoneName)
    document.addEventListener("keydown", _onKeyDown)
    return () => document.removeEventListener("keydown", _onKeyDown)
  }, [])

  const _onKeyDown = () => {
    setStatus("")
    setMessage("")
  }

  const _onSubmit = (e) => {
    e.preventDefault();

    if(!data)return
    
//     fetch("/.netlify/functions/reset", {
//       method: 'post',
//       headers: {
//         'Content-type': 'application/json',
//       }
//       // body: JSON.stringify({ 
//       //   zoneId,
//       //   uuid: uuid(),
//       //   data
//       //  })
//     })
//     .then(response => response.json())
//     .then(result => {
//       // console.log(result)
//       setStatus("done")
//       onSuccess()
//     })
//     .catch(error => {
//       console.error(error)
//       setStatus("error")
//       // cb(error)
//     })
// return
    
    // return
    const isValidForm = _isFormValid()
    console.log("isValidForm", isValidForm)
    if(!isValidForm){
      setStatus("form-not-valid")
      setMessage("Pb Form")
      return
    }

    const isCodeValid = _isCodeValid()
    // console.log("isCodeValid", isCodeValid)
    if(!isCodeValid){
      setStatus("form-not-valid")
      setMessage("Pb Code Bank")
      return
    }
    // return

    setStatus("sending")
    
    // const _id = uuid()

    const payload = {
      zoneId,
      zoneName,
      uuid: uuid(),
      data
    }
    console.log(payload)

    fetch("/.netlify/functions/insert", {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        // Authorization: `Bearer sk7JbAetASnDnH2YBFVkRqd284Gd3lunG05FzzDlLaK5uBdhojrPNu2aDl6g16X3gThtolvifVQYDp08x`
      },
      body: JSON.stringify({ 
        zoneId,
        uuid: uuid(),
        data
       })
    })
    .then(response => response.json())
    .then(result => {
      console.log(result)
      setStatus("done")
      onSuccess()
    })
    .catch(error => {
      console.error(error)
      setStatus("error")
      // cb(error)
    })

    // return
    // _insertUser(_id, (result) => {
    //   _patchUserWithZone(_id, (result) => {
    //     console.log("done")
    //     setStatus("done")
    //     onSuccess()
    //   })
    // })
  }

  const _isFormValid = () => {
    let isValid = true
    fields.forEach(el => {
      // console.log(data[el.name])
      if(!data[el.name] || data[el.name] == ""){
        isValid = false
      }
      
    })

    return isValid
  }

  const _isCodeValid = () => {
    const inputCode = data["code_banquier"]
    const exist = codesBanque.filter(el => el === inputCode)
    // console.log(exist, inputCode)
    return exist.length === 1
  }

  const _renderInput = (input) => (
    <input
      {...input}
      placeholder={`${_getLocalizedText(input.name)}*`}
      key={input.name}
      className=""
      required
      // onBlur={setStatus("")}
      onChange={_normalizeValueByType}
      // defaultValue={state[input.name]}
    />
  )

  const _renderCheckbox = (input) => (
    <input
      {...input}
      // placeholder={`${_getLocalizedText(input.name)}*`}
      key={input.name}
      className=""
      required
      // onBlur={setStatus("")}
      onChange={(e) => {
        setData({ 
          ...data,
          [e.target.name]: e.target.value
        })
      }}
      // defaultValue={state[input.name]}
    />
  )

  const _normalizeValueByType = (e) => {
    // console.log(data)
    // console.log(e.target.type)
    const value = e.target.type === "number" 
    ? parseFloat(e.target.value)
    : e.target.value

    // const value = e.target.type === "number" 
    
    setData({ 
      ...data,
      [e.target.name]: value
    })
  }

  // const _testMax = (e) => {
  //   return data.contribution > contributionMax
    
  // }

  

  return (
    <div className="form-contribution">
      <div className="description">
        <div className="fPrim strong ttu">{_getLocalizedText("information")}</div>
        <p>{_getLocalizedText("informationMessage")}</p>
      </div>
      
      <form action="" onSubmit={_onSubmit} className={status}>
        <div className="fPrim strong ttu">{_getLocalizedText("contact")}</div>

        {fields.map((input,i) => {
          if(input.type === "checkbox"){
            return(
              <div className="form-row" key={input.name}>
                <div className="checkbox x xac">
                <input type="checkbox" id="optin" name="optin" onChange={(e) => {
                  // console.log(e.target.checked)
                  setData({ 
                    ...data,
                    [e.target.name]: Boolean(e.target.checked)
                  })
                }}/>
                <label htmlFor="optin">{_getLocalizedText("messageOptin")}</label>
                </div>
              </div>
            )
          }else{
            return(
              <div key={input.name}>{_renderInput(input)}</div>
            )
          }
        })}
        
        <div className="form-row">
          {status === "form-not-valid" &&
            <p className="error">{message}</p>
          }
          {/*{status === "loading" &&
            <p className="error">Champs requis</p>
          } */}
        </div>
        <div className="form-row">
          <input type="hidden" name="zone" value={zoneId} />
          <button type="submit" className="x xjb xac">
            <span>{_getLocalizedText("confirm")}</span>
            <span className="icon icon-arrow-e"></span>
          </button>

        </div>
      </form>
      
    </div>
  );
};

export default Form;